import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/SEO"

const About = () => {
  return (
    <Layout>
      <SEO title="About" />
      <main className="about-page page">
      <section>
        <div>
          <div className="about-columns">
            <div className="logoScreen">
            <ul className="logoImg">
            <li><StaticImage
            src="../assets/images/logo.png"
            alt="Apsiha"
            className="logoInner"
            placeholder="DOMINANT_COLOR"
            //layout="constrained"
            ></StaticImage>
            <h2 className="center hidden-lg"><strong className="pageNaslov">Apsiha </strong><span id="naslov_span"> Psihoterapija</span></h2>
            </li>
            </ul>
            </div>
            <div>
            <p>Tako nam je drago da si tu!</p> 
            <p>Vjerojatno prolaziš neku izazovnu fazu ili promišljaš životnu promjenu. Pitaš se i nastojiš 
            dokučiti jesmo li mi ono što ti je sada potrebno. Svakako ti želimo pomoći u odluci.
            </p>
            <p>
            Nastojat ćemo odgovoriti na tvoja pitanja, osluškivati tvoje potrebe, biti prisutne, pružiti podršku, 
            hraniti nadu i veseliti se tvom napretku. Naša praksa nudi savjetovanje i psihoterapiju koje su:
            </p>
            <ul className="popisX">
              <li>Temeljene na znanjima o traumi</li>
              <li>Fleksibilne</li>
              <li>Kulturno osjetljive</li>
              <li>Bilingvalne (dostupne na engleskom i hrvatskom jeziku)</li>
            </ul>
            <p>
            Apsihu smo osnovale 2016. godine jer smo htjele stvoriti praksu u kakvu bismo i same htjele ušetati, 
            a ohrabrene dugogodišnjim i međunarodnim iskustvom u kliničkoj, savjetodavnoj i organizacijskoj psihologiji.
            </p>
            <p>
            Kontinuirano se educiramo i superviziramo naš rad kako bi osigurale da budeš u dobrim i kompetentnim rukama 
            na svom terapijskom putu. Možeš saznati više o tome što nudimo na dijelu straice posvećenom <Link to="../Services">uslugama</Link>.
            </p>
            <p>
            Možeš nas posjetiti osobno ili se odlučiti za videopoziv. Bez obzira na kanal komunikacije koji preferiraš, 
            detalje možeš pronaći <Link to="../Contact">tu</Link>.
            </p>
            <br/> 
            </div>
            </div>
            <div className="profile">
              <h2><strong className="pageNaslov">Apsiha </strong>u medijima</h2>
              <p>
              Ovdje možete pronaći video zapise i poveznice na članke i kolumne naših psihologinja
              </p>
              <div>
              <Link to="/Media" className="btn">
                Prikaži više &raquo;
              </Link>
              </div>
          </div>
          <div className="container">
            <div className="profile boxed1" id="ivana">
              <div>
              <StaticImage
              src="../assets/images/ivana.jpeg"
              alt="Ivana"
              className="ivana-profile-img"
              placeholder="DOMINANT_COLOR"
              />
              </div>
              <div className="profileTextIvana">
              <h2>Ivana Mrgan <span className="kurziv"> univ. spec. clin. psych.</span></h2>
              <p> Otvorenost prema različitim iskustvima, empatija i autentični odnosi su nešto što me pratilo u karijeri, a to su ujedno i 
                vrijednosti koje donosim sa sobom u naš odnos ako se odlučiš za suradnju. Posebno me veseli praksa temeljena na istraživanjima,
                multikulturalizam i kulturno osjetljiv pristup klijentima. <br/>
                Pretpostavljam da vidiš da ti neko preslagivanje ne gine i veselim se prilici da budem dio te pustolovine.</p>
              <div className="IvanaBtn">
              <Link to="/Ivana"  className="btn">
                Prikaži više &raquo;
              </Link>
              </div>
              </div>
            </div>
            <div className="profile boxed2" id="ana">
              <div>
              <StaticImage
              src="../assets/images/ana.jpeg"
              alt="Ana"
              className="ana-profile-img"
              placeholder="DOMINANT_COLOR"
              />
              </div>
              <div className="profileTextAna">
              <h2>Ana Miletić<span className="kurziv"> prof. psych.</span></h2>
              <p>Oduvijek su mi ljudi bili zanimljivi i u psihoterapiji sam pronašla mjesto gdje su moje radoznalost i 
                prisutnost najbolje iskorištene. Smatram privilegijom biti blizu najranjivijih ljudskih iskustava i stoga 
                nastojim biti pažljiva, ali i odvažna u dolasku do i prolasku kroz njih. U meni možeš pronaći razumijevanje, 
                podršku i povjerenje u tebe i tvoje mogućnosti na tom putu.
              </p>
              <div>
              <Link to="/Ana" className="btn">
                Prikaži više &raquo;
              </Link>
              </div>
              </div>
            </div>
          </div>
            <div className="profile">
              <h2>Origami</h2>
              <p>
              Umjetnik koji stoji iza origamija na stranicama Apsihe je Jo Nakashima. Ako vas zanima izrada istih, provjerite njegovu stranicu
              </p>
              <a href="https://jonakashima.com.br/" className="btn">
                Jo Nakashima
              </a>
              </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default About;